import React, { useState } from "react";
import "../CancellationPolicy/CancellationPolicy.css";

function CancellationPolicy() {
  const [feesCancellation, setFeesCancellation] = useState("tab-1");

  function handleShowContent(tab) {
    setFeesCancellation(tab);
  }
  return (
    <div className="cancellationPolicy">
      <div className="main">
        <div className="main-div">
          <div className="wrapper">
            <div className="main-content">
              <div className="main-content-header  f-color">
                <h1 className="f-40">Cancellation Of Agreement Policy</h1>
                <h2 className="f-32 fw-700">Agreement Termination:</h2>
              </div>
            </div>
            <div className="main-content">
              <div className="main-content-paragraph  f-color">
                <p className="f-25 fw-500">
                  This agreement between the payment gateway provider and the
                  user or merchant can be terminated by either party at any
                  time. The termination notice must be given in writing and
                  delivered to the other party at least 30 days prior to the
                  intended date of termination.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tab">
        <div className="wrapper">
          <div className="cancellation-content">
            <div className="cancellation-content-left w-50">
              <div className="cancellation-content-left-heading">
                <h2 className="f-color">
                  SELECT FROM BELOW TO VIEW THE POLICIES
                </h2>
              </div>
              <div className="cancellation-left-content">
                <div className="cancellation-data-box" onClick={() =>handleShowContent("tab-1")}>
                  <h5 className="fw-700 f-color f-20">IMMEDIATE TERMINATION</h5>
                </div>
                <div className="cancellation-data-box" onClick={() =>handleShowContent("tab-2")}>
                  <h5 className="fw-700 f-color f-20">FEES AND CHARGES</h5>
                </div>
              </div>
              <div className="cancellation-left-content" >
                <div className="cancellation-data-box" onClick={() =>handleShowContent("tab-4")}>
                  <h5 className="fw-700 f-color f-20">USER DATA</h5>
                </div>
                <div className="cancellation-data-box" onClick={() =>handleShowContent("tab-3")}>
                  <h5 className="fw-700 f-color f-20">COMPLIANCE</h5>
                </div>
              </div>
              <div className="cancellation-left-content">
                <div className="cancellation-data-box" onClick={() =>handleShowContent("tab-5")}>
                  <h5 className="fw-700 f-color f-20" >LIABILITY</h5>
                </div>
                <div className="cancellation-data-box" onClick={() =>handleShowContent("tab-6")}>
                  <h5 className="fw-700 f-color f-20">GOVERNING LAW</h5>
                </div>
              </div>
            </div>
            <div className="cancellation-content-right  w-50 ">
              <div className={`cancellation-content-right-heading ${feesCancellation =="tab-1"?"active":""}`} >
                <h5 className="f-30 fw-700 f-color">IMMEDIATE TERMINATION</h5>

                <ol>
                  <li>
                    <strong>Termination for Breach:- </strong>Either Party may
                    terminate this Agreement with immediate effect if the other
                    Party commits any breach of the terms of this Agreement.
                  </li>
                  <li>
                    <strong>Termination in Case of Violation of Law:- </strong>{" "}
                    In addition to any other termination rights granted by this
                    Agreement, the Payment Gateway Service Provider may
                    terminate this Agreement immediately without liability upon
                    verbal or written notice if (i) the Payment Gateway Service
                    Provider is notified or otherwise determines in good faith
                    that the Merchant is using the Service Providers services
                    and facilities in furtherance of any activity which violates
                    any law, rule, or regulation.
                  </li>
                </ol>
              </div>

              <div className={`cancellation-content-right-heading ${feesCancellation =="tab-2"?"active":""}`}>
                <h5 className="f-30 fw-700 f-color">FEES AND CHARGES</h5>
                <p className="f-color f-20">
                  Any fees or charges paid by the user or merchant for using the
                  payment gateway are non-refundable upon agreement termination.
                  However, any fees or charges already paid for services or
                  transactions that have not yet been processed will be refunded
                  in full to the user or merchant.
                </p>
              </div>

              <div className={`cancellation-content-right-heading ${feesCancellation =="tab-3"?"active":""}`}>
                <h5 className="f-30 fw-700 f-color">COMPLIANCE</h5>
                <p className="f-color f-20">
                  The user or merchant must comply with all applicable laws and
                  regulations regarding the use of the payment gateway,
                  including but not limited to anti-fraud and anti-money
                  laundering laws.
                </p>
              </div>

              <div className={`cancellation-content-right-heading ${feesCancellation =="tab-4"?"active":""}`}>
                <h5 className="f-30 fw-700 f-color">USER DATA</h5>
                <p className="f-color f-20">
                  Upon agreement termination, the payment gateway provider will
                  return all user data to the user or merchant in a format
                  agreed upon by both parties.
                </p>
              </div>
              <div className={`cancellation-content-right-heading ${feesCancellation =="tab-5"?"active":""}`}>
                <h5 className="f-30 fw-700 f-color">LIABILITY</h5>
                <p className="f-color f-20">
                  The payment gateway provider shall not be liable for any
                  damages, including but not limited to loss of profits, arising
                  from the termination of this agreement.
                </p>
              </div>
              <div className={`cancellation-content-right-heading ${feesCancellation =="tab-6"?"active":""}`}>
                <h5 className="f-30 fw-700 f-color">GOVERNING LAW</h5>
                <p className="f-color f-20">
                  This agreement shall be governed by and construed in
                  accordance with the laws of the jurisdiction in which the
                  payment gateway provider is based.
                </p>
                <p className="f-color f-20">
                  By using our payment gateway, users and merchants agree to
                  abide by this Cancellation of Agreement Policy. We reserve the
                  right to modify this policy at any time without prior notice.
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
       
      </div>
    </div>
  );
}

export default CancellationPolicy;
