import React from 'react';
import "./Footer.css"
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.jpg';
import Merchant from '../../assets/Merchant-Policy.docx';


function Footer() {
  return (
    <footer>
      <div className="footer wrapper d-flex  gap-20">
        
        <div className="menus d-flex grow-1 justify-between gap-10">
            <div className="aboutus w-4">
                <h4>About Us</h4>
                <div >
                   <div className="logo">
                   <img  src={logo} alt="logo" />   
                   </div>
                   <p className="gray">
                        Instapaymax enables businesses to collect online & offline payments across 150+ payment modes such as Credit Cards, Debit Cards, Net Banking, EMIs, BNPL, QR, UPI, Wallets and more.   
                   </p>
                </div>
            </div>
          <div className="Important-links w-4">
            <h4>Important Links</h4>  
            <div>
                
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/contact">Contact Us</Link>
              <Link to="/developers-guide">Developers Guide</Link>
              <Link to="/api-references-guide">API References</Link>
              <Link to="/payment-gateway">Payment Gateway</Link>
            </div>
          </div>
         
          <div className="Featured w-4">
            <h4>Featured Services</h4>
            <div>
              <Link to='/web-checkout'>Web checkout</Link>
              <Link to='/payment-links'> Payment Links</Link>
              <Link to='/payment-buttons'> Payment Buttons </Link>
              <Link to='/recurring-payments'>Recurring Payments</Link>
              <Link to='/mobile-app-sdk'>Mobile App SDK</Link>
            </div>
          </div>
          <div className="Connect w-4">
            <h4>Contact Us</h4>
            <div className="gray">
                <p className='mb-20'>Office Address </p>
             
                <p className='mb-20'>325 2nd Floor, Casa Del Sol Wing 3 Opposite Marriott Hotel Miramar Panjim Goa -403001</p>
                <p className='mb-20'>Phone: +91-8799906286</p>
                <p >Email: support@instapaymax.com</p>
            </div>
            
          
          </div>

        </div>

      </div>
      <div className="footer-middle wrapper">
            <div className='d-flex justify-between'>
                <Link to="/terms-of-use"> Terms of Use</Link>
                <Link to="/privacy-policy "> Privacy Policy </Link>
                <Link to="/grievance-redressal"> Grievance Redressal</Link>
                <Link to="/cancellation-policy"> Cancellation Policy</Link>
                <a href={Merchant} download="Merchant-Policy" title='Download Merchant Policy'>Merchant Policy</a>
            </div>

        </div>
      <div className="  footer-bottom">
        <div className="wrapper center">
        <p>Copyright © 2023 Instanow Info systems Pvt Ltd | All Rights Reserved.</p>
        </div>
       

      </div>
    </footer>
  );
}

export default Footer;
